import React from "react"
import { Link } from "gatsby"
import "./styles.scss"

function BisonButton({ children, link, text, isDark }) {
  return (
    <Link to={link} className={`bison-btn ${isDark ? "is-dark" : ""}`}>
      <svg width="180px" height="50px" viewBox="0 0 180 50" className="border">
        <polyline points="179,1 179,49 1,49 1,1 179,1" className="bg-line" />
        <polyline points="179,1 179,49 1,49 1,1 179,1" className="hl-line" />
      </svg>
      <span>{children}</span>
    </Link>
  )
}

export default BisonButton
