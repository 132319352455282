import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import posed, { PoseGroup } from "react-pose"
import {
  FiPlayCircle,
  FiPauseCircle,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi"
import "./styles.scss"

let AnimatableLi = posed.li({
  enter: {
    opacity: 1,
    transition: {
      duration: 500,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 500,
    },
  },
})

export default function Slider() {
  const data = useStaticQuery(graphql`
    query SlideAtaQuery {
      allPrismicHomepage {
        edges {
          node {
            data {
              slider {
                slide_background_image {
                  fluid(maxWidth: 2000) {
                    src
                    srcWebp
                    srcSetWebp
                    srcSet
                    sizes
                    base64
                    aspectRatio
                  }
                }
                slide_caption {
                  text
                }
                slide_heading {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)

  const slides = data.allPrismicHomepage.edges[0].node.data.slider
  let [sliderIndex, setSlideIndex] = useState(0)
  let [isPlaying, setIsPlaying] = useState(true)

  const handleSlideDown = () => {
    if (sliderIndex <= 0) {
      setSlideIndex(slides.length - 1)
    } else {
      setSlideIndex(sliderIndex - 1)
    }
  }

  const handleSlideUp = () => {
    if (sliderIndex >= slides.length - 1) {
      setSlideIndex(0)
    } else {
      setSlideIndex(sliderIndex + 1)
    }
  }

  useEffect(() => {
    let sliderTimeout = null
    if (isPlaying) {
      sliderTimeout = setInterval(() => {
        setSlideIndex(handleSlideUp)
      }, 5000)
    }
    return () => {
      window.clearTimeout(sliderTimeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, sliderIndex])

  return (
    <section className="slider">
      <ul>
        <PoseGroup>
          {slides.map((slide, indx) => {
            return (
              (indx === sliderIndex && (
                <AnimatableLi key={indx}>
                  <Img
                    className="slide__bg-image"
                    fluid={slide.slide_background_image.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                  />
                  <h2 className="slide__title">{slide.slide_heading.text}</h2>
                  <p className="slide__caption">{slide.slide_caption.text}</p>
                </AnimatableLi>
              )) ||
              null
            )
          })}
        </PoseGroup>
      </ul>
      <div className="slider__pagination">
        <FiChevronsLeft onClick={() => setSlideIndex(handleSlideDown)} />
        {isPlaying ? (
          <FiPauseCircle onClick={() => setIsPlaying(false)} />
        ) : (
          <FiPlayCircle onClick={() => setIsPlaying(true)} />
        )}
        <FiChevronsRight onClick={() => setSlideIndex(handleSlideUp)} />
      </div>
    </section>
  )
}
