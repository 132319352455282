import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Slider from "../components/Slider"
import HalfContentWithImage from "../components/HalfContentWithImage"
import PropositionBar from "../components/PropositionBar"

const IndexPage = ({ data }) => {
  const {
    intro_title,
    intro_content,
    intro_image,
    proposition_bar_title,
    proposition_bar_subtitle,
  } = data.allPrismicHomepage.edges[0].node.data
  return (
    <Layout>
      <SEO title="Home" />
      <Slider />
      <PropositionBar
        title={proposition_bar_title.text}
        subtitle={proposition_bar_subtitle.text}
      />
      <HalfContentWithImage
        title={intro_title}
        content={intro_content}
        image={intro_image}
        btnLink={"/services"}
        btnText={"Our Services"}
      />
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    allPrismicHomepage {
      edges {
        node {
          data {
            intro_title {
              text
            }
            intro_content {
              html
            }
            intro_image {
              fluid(maxWidth: 1000) {
                src
                srcWebp
                srcSetWebp
                srcSet
                sizes
                base64
                aspectRatio
              }
            }
            proposition_bar_title {
              text
            }
            proposition_bar_subtitle {
              text
            }
          }
        }
      }
    }
  }
`
export default IndexPage
