import React from "react"
import Img from "gatsby-image"
import "./styles.scss"
import BisonButton from "../common/Button"

const HalfContentWithImage = ({
  title,
  content,
  image,
  btnLink,
  btnText,
  contentLeft,
}) => {
  return (
    <section className={`half-content ${contentLeft ? "content-left" : ""}`}>
      <div className="half-content__content-container">
        <h2 className="half-content__title">{title.text}</h2>
        <div
          className="half-content__content"
          dangerouslySetInnerHTML={{ __html: content.html }}
        ></div>
        <BisonButton link={btnLink}>{btnText}</BisonButton>
      </div>
      <div className="half-content__image-container">
        <Img className="half-content__image" fluid={image.fluid} />
      </div>
    </section>
  )
}

export default HalfContentWithImage
