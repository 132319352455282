import React from "react"
import BisonButton from "../common/Button"
import "./styles.scss"

const PropositionBar = ({ title, subtitle }) => {
  return (
    <section className="proposition-bar">
      <h2 className="proposition-bar__title">{title}</h2>
      <p className="proposition-bar__subtitle">{subtitle}</p>
      <BisonButton link="/about" isDark={true}>
        Learn more
      </BisonButton>
    </section>
  )
}

export default PropositionBar
